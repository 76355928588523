import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import axios from 'axios';
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { jwtDecode } from "jwt-decode";
import { FaPlus } from "react-icons/fa6";
import { SiMicrosoftexcel } from "react-icons/si";
import { TbEdit, TbEye, TbTrash } from 'react-icons/tb'

import Loading from '../../loading.jsx'

// Import Metadata
import Metadata from '../../../common/metadata.js'

const DataUser = () => {
  document.title = `Data User ${Metadata.exTitle}`

  const [role, setRole] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [expire, setExpire] = useState('')
  const [data, setData] = useState(null);
  const history = useHistory()

  useEffect(() => {
    const fetchData = async (accessToken_) => {
      try {
        const response = await axios.get(`${Metadata.apiOrigin}/user/getAll`, {
          headers: {
            'Authorization': `Bearer ${accessToken_}`
          }
        })

        setData(response.data)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    const refreshTokenAndFetchData = async () => {
      const accessToken_ = await refreshToken();
      await fetchData(accessToken_);
    };

    refreshTokenAndFetchData();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${Metadata.apiOrigin}/token`)
      setAccessToken(response.data.accessToken)
      const decoded = jwtDecode(response.data.accessToken)
      setRole(decoded.role)
      setExpire(decoded.exp)
      return response.data.accessToken
    } catch (error) {
      if (error.response) {
        history.push('/')
      }
    }
  }

  // refreshToken()
  const axiosJWT = axios.create()

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
      const response = await axios.get(`${Metadata.apiOrigin}/token`)
      setAccessToken(response.data.accessToken)
      config.headers.Authorization = `Bearer ${response.data.accessToken}`
      const decoded = jwtDecode(response.data.accessToken)
      setRole(decoded.role)
      setExpire(decoded.exp)
    }
    return config
  }, (error) => {
    return Promise.reject(error)
  })

  let i = -2;

  const handleIncrement = () => {
    i++;
  }

  // DELETE
  const deleteDataHandler = (id_) => {
    DeleteData(id_);
  };

  function DeleteData(id_) {
    axiosJWT.post(`${Metadata.apiOrigin}/user/delete`, { "id": id_ }, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }).then(function (response) {
      window.location.reload();
    }).catch(function (error) {
      // console.log(error);
    });
  }

  if (!data) {
    return <Loading />
  }

  // You can decide what to render based on the data
  return (
    <section className="bg-white w-full h-full md:pl-72 py-14 relative px-4">
      <div className="flex flex-col gap-4">
        {/* Header */}

        <div>
          <h1 className="text-gray-800 text-left text-5xl selection:text-black font-semibold">
            User Data
          </h1>
        </div>
        <div className="flex flex-row gap-4">
          <Link to="/user/create">
            <button className="bg-blue-600 p-2 px-6 rounded-md shadow-md font-semibold flex items-center gap-2 hover:opacity-75 text-white duration-300 hover:scale-105">
              <FaPlus />Add New
            </button>
          </Link>
          {data?.rows && <CSVLink data={data.rows} filename="Data-siswa" className="bg-blue-600 p-2 px-6 rounded-md shadow-md font-semibold flex items-center gap-2 hover:opacity-75 text-white duration-300 hover:scale-105">
            <SiMicrosoftexcel></SiMicrosoftexcel>Export to CSV
          </CSVLink>}
        </div>

        {/* Table Siswa */}

        <table className="rounded-xl shadow-xl w-full text-center mb-3 overflow-hidden">
          <thead className="bg-blue-600 border  text-white">
            <tr>
              <th className="px-2 py-4 md:px-2 md:py-4 lg:px-2 lg:py-4 w-1">No</th>
              <th className="px-4">Username</th>
              <th className="px-4">NRP/NISN/KTP</th>
              <th className="px-4">Role</th>
              <th className="px-4 min-w-[200px]">Mentor Of</th>
              <th className="px-4">Instance</th>
              <th className="px-4">Class/Major</th>
              <th className="px-4">Description</th>
              <th className="px-4 min-w-[200px]">Joined At</th>
              <th className="px-4">Action</th>
            </tr>
          </thead>

          {/* Data For table */}

          <tbody className="text-black border-t bg-white">
            {data.rows && (data.rows).map((item, index) => {
              handleIncrement();
              return item.name !== 'admin' && <tr key={index}>
                <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black ">{(i + 1)}</td>
                <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black ">{item.name}</td>
                <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black ">{item.nrp_nisn_ktp}</td>
                <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black ">{item.role}</td>
                <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black capitalize">{(item.courseName) ? (item.courseName).replaceAll('_', ' ') : ''}</td>
                <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black ">{item.instance}</td>
                <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black ">{item.classmajor}</td>
                <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black ">{item.description}</td>
                <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black ">{new Date(item.created_at).toDateString()}</td>
                <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black transition-all ease-in text-white">
                  <div className="flex flex-col md:flex-row gap-2 ">
                    {role === 'admin' && <><button onClick={() => deleteDataHandler(item.id)} className="bg-red-500 rounded-md px-1 py-2 md:py-2 md:px-3 hover:opacity-70 duration-500 [&>*:first-child]:hover:-rotate-12 flex justify-center">
                      <TbTrash strokeWidth={'1'} className="w-6 h-6" />
                    </button><Link to={`/user/update/${item.name}`} className="bg-green-500 rounded-md px-1 py-2 md:py-2 md:px-3 hover:opacity-70 duration-500 [&>*:first-child]:hover:-rotate-12 [&>*:first-child]:duration-200 flex justify-center">
                        <TbEdit strokeWidth={'1'} className="w-6 h-6" />
                      </Link></>}
                    <Link to={`/user/profile/${item.name}`} className="bg-blue-500 rounded-md px-1 py-2 md:py-2 md:px-3 hover:opacity-70 duration-500 [&>*:first-child]:hover:animate-bounce [&>*:first-child]:duration-200 flex justify-center">
                      <TbEye strokeWidth={'1'} className="w-6 h-6" />
                    </Link>
                  </div>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default DataUser;
