import { useEffect, useState } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";

import Loading from '../../loading.jsx'

// Import Metadata
import Metadata from '../../../common/metadata.js'
import { useParams } from "react-router-dom/cjs/react-router-dom.js";

const DataPresenceUser = () => {
  const { name } = useParams()
  const { course } = useParams()
  document.title = `Data Presence ${name} ${Metadata.exTitle}`

  const [expire, setExpire] = useState('')
  const history = useHistory()

  useEffect(() => {
    const fetchData = async (accessToken_) => {
      try {
        const response = await axios.get(`${Metadata.apiOrigin}/user/presence/${course}/getByName/${name}`, {
          headers: {
            'Authorization': `Bearer ${accessToken_}`
          }
        })
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error.response);
      }
    };

    const refreshTokenAndFetchData = async () => {
      const accessToken_ = await refreshToken();
      fetchData(accessToken_);
    };

    refreshTokenAndFetchData(); // Panggil fungsi ini sekali saat komponen dimuat pertama kali
  }, []); // Kosongkan dependensi untuk memastikan efek hanya berjalan sekali

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${Metadata.apiOrigin}/token`)
      const decoded = jwtDecode(response.data.accessToken)
      setExpire(decoded.exp)
      return response.data.accessToken
    } catch (error) {
      if (error.response) {
        history.push('/')
      }
    }
  }


  // refreshToken();

  const axiosJWT = axios.create()

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
      const response = await axios.get(`${Metadata.apiOrigin}/token`)
      config.headers.Authorization = `Bearer ${response.data.accessToken}`
      const decoded = jwtDecode(response.data.accessToken)
      setExpire(decoded.exp)
    }
    return config
  }, (error) => {
    return Promise.reject(error)
  })

  const [data, setData] = useState(null);

  if (!data) {
    return <Loading />
  }

  return (
    <section className="bg-white min-w-full h-full relative md:pl-64">
      <div className="border shadow-md rounded-xl flex w-full h-screen overflow-y-scroll justify-center">
        <div className="flex flex-col gap-4 w-full p-4 md:p-10 rounded-xl">
          {/* Header */}

          <div>
            <h1 className="text-gray-800 text-left text-5xl font-semibold">Data Presence {name}</h1>
          </div>

          <div className="flex flex-row gap-4">
            {data?.rows && <CSVLink data={data.rows} filename={`Data-kehadiran-${name}`} className="bg-blue-600 p-2 px-6 rounded-md shadow-md font-semibold flex items-center gap-2 hover:opacity-75 text-white duration-300 hover:scale-105">
              <i className="fa-solid fa-plus"></i>Export to CSV
            </CSVLink>}
          </div>

          {/* Table Siswa */}

          <div>
            <table className="rounded-xl shadow-xl w-full text-center mb-3 overflow-hidden">
              <thead className="bg-blue-600 border  text-white">
                <tr>
                  <th className="px-2 py-4 md:px-2 md:py-4 lg:px-2 lg:py-4 w-1">No</th>
                  <th className="px-4">Name</th>
                  <th className="px-4">Task Link</th>
                  <th className="px-4">Date</th>
                  <th className="px-4">Status</th>
                </tr>
              </thead>

              {/* Table for Data */}
              <tbody className="text-black border-t bg-white">

                {data && (data.rows).map((item, index) => (
                  <tr key={index}>
                    <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black">{index + 1}</td>
                    <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black">{item.name}</td>
                    <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black">
                      {item.task_link && <a href={item.task_link} className="text-blue-400 hover:opacity-70" target="_blank">{item.task_link.substring(0, 20)}{item.task_link.length > 20 && '...'}</a>}
                    </td>
                    <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black">{new Date(item.created_at).toDateString()}</td>
                    <td className="border-t px-1 py-2 md:px-1 md:py-2 lg:px-10 border-black transition-all ease-in">
                      <div className="flex flex-row gap-2 justify-center">
                        <span>Present</span>
                      </div>
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DataPresenceUser;