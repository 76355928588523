import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

import Loading from '../../loading.jsx'

// Import Metadata
import Metadata from '../../../common/metadata.js'

const initialErrors = {
  tname: '',
  tpassword: '',
  tinstance: '',
  tclassmajor: '',
};

const Update = () => {
  document.title = `Update User ${Metadata.exTitle}`
  const { name } = useParams()

  const [allMentor, setAllMentor] = useState(null)
  const [allCourse, setAllCourse] = useState(null)
  const [mentorId, setMentorId] = useState(null)
  const [userId, setUserId] = useState(null)
  const [name_, setName] = useState(name)
  const [role, setRole] = useState('user')
  const [courses, setCourses] = useState('')
  const [nrpNisnKtp, setNrpNisnKtp] = useState('')
  const [courseId, setCourseId] = useState(null)
  const [instance, setInstance] = useState('')
  const [classMajor, setClassMajor] = useState('')
  const [description, setDescription] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [expire, setExpire] = useState('')
  const [errors, setErrors] = useState(initialErrors)
  const [arrayNameAllCourse, setArrayNameAllCourse] = useState(null);

  const history = useHistory()

  useEffect(() => {
    const fetchData = async (accessToken_) => {
      try {
        const response = await axios.get(`${Metadata.apiOrigin}/user/getByName/${name}`, {
          headers: {
            'Authorization': `Bearer ${accessToken_}`
          }
        });
        setUserId(response.data.row.id)
        if (response.data.row.mentor_id !== '')
          setMentorId(response.data.row.mentor_id)
        if (response.data.row.role !== '') {
          setRole(response.data.row.role)
        }
        setNrpNisnKtp(response.data.row.nrp_nisn_ktp)
        setCourseId(response.data.row.course_id)
        setInstance(response.data.row.instance)
        setClassMajor(response.data.row.classmajor)
        setDescription(response.data.row.description)
        setCourses(response.data.row.courses)

        const allCourseName = response.data.row.courses.split('&')
        setArrayNameAllCourse(allCourseName)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchDataMentor = async (accessToken_) => {
      try {
        const response = await axios.get(`${Metadata.apiOrigin}/user/getAllMentor`, {
          headers: {
            'Authorization': `Bearer ${accessToken_}`
          }
        });
        setAllMentor(response.data.rows);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchDataCourse = async (accessToken_) => {
      try {
        const response = await axios.get(`${Metadata.apiOrigin}/getAllCourse`, {
          headers: {
            'Authorization': `Bearer ${accessToken_}`
          }
        });
        setAllCourse(response.data.rows);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    const refreshTokenAndFetchData = async () => {
      const accessToken_ = await refreshToken();
      await fetchData(accessToken_);
      await fetchDataMentor(accessToken_)
      await fetchDataCourse(accessToken_)
    };

    refreshTokenAndFetchData();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${Metadata.apiOrigin}/token`)
      setAccessToken(response.data.accessToken)
      const decoded = jwtDecode(response.data.accessToken)
      setExpire(decoded.exp)
      return response.data.accessToken
    } catch (err) {
      // console.log(err);
    }
  }

  // refreshToken()
  const axiosJWT = axios.create()

  axiosJWT.interceptors.request.use(async (config) => {
    const currentDate = new Date()
    if (expire * 1000 < currentDate.getTime()) {
      const response = await axios.get(`${Metadata.apiOrigin}/token`)
      setAccessToken(response.data.accessToken)
      config.headers.Authorization = `Bearer ${response.data.accessToken}`
      const decoded = jwtDecode(response.data.accessToken)
      setExpire(decoded.exp)
    }
    return config
  }, (error) => {
    return Promise.reject(error)
  })

  function UpdateHandler(e) {
    e.preventDefault()
    UpdateUser()
  }

  function handleCourses(value) {
    let coursesArray = courses.split('&')
    if (coursesArray.length != 0) {
      if (coursesArray.includes(value)) {
        let i = coursesArray.indexOf(value)
        coursesArray.splice(i, 1)
      } else {
        coursesArray.push(value)
      }
    } else {
      coursesArray.push(value)
    }

    let courses_ = ''
    coursesArray.forEach(courseArray => {
      if (courses_ !== '')
        courses_ += '&' + courseArray
      else
        courses_ += courseArray
    });
    setCourses(courses_)
  }

  function handleRole(value) {
    if (value !== 'mentor' && value != 'admin') {
      setCourseId(null)
    } else {
      if (courseId === null) {
        setCourseId(allCourse[0].id)
      }
    }

    setRole(value)
  }

  function UpdateUser() {

    const name__ = (name_ === '') ? 'Name required *' : ''

    if (mentorId === '') {
      setMentorId(null)
    }
    if (instance === '') {
      setInstance(null)
    }
    if (classMajor === '') {
      setClassMajor(null)
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      tname: name__,
      tinstance: instance,
      tclassmajor: classMajor,
    }))

    if (name__ === '') {
      axiosJWT.post(`${Metadata.apiOrigin}/user/update`, {
        "id": userId,
        "name": name_,
        "nrp_nisn_ktp": nrpNisnKtp,
        "mentor_id": mentorId,
        "instance": instance,
        "classmajor": classMajor,
        "role": role,
        "course_id": (courseId !== '' || courseId !== null || role === 'mentor') ? courseId : null,
        "description": description,
        "courses": courses
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((res) => {
        return history.push('/data/users')
      }).catch((err) => {
        // console.log(err);
      })
    }
  }

  if (!userId && !allCourse && !allMentor) {
    return <Loading />
  }

  return (
    <form className="bg-white py-14 w-full h-full flex justify-center items-center">
      <section className="bg-transparent border backdrop-blur-2xl bg-opacity-30 bg-white shadow-md rounded-xl w-[90%]  max-w-md p-10 flex flex-col">
        <div>
          <h1 className="text-3xl font-semibold pb-10">
            Edit User
          </h1>
        </div>

        {/* Mentor */}

        <div className="flex flex-col">
          <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
            Mentor
          </label>
          <div className="relative">
            <select className="hover:cursor-pointer appearance-none w-full   border text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-black" id="grid-state" value={`${courseId}`} onChange={(e) => setMentorId(e.target.value)}>
              <option value={null}>Without Mentor</option>
              {allMentor && allMentor.map((mentor, index) => (
                <option value={mentor.id} key={index}>{mentor.name}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Name */}

        <div className="flex flex-col">
          <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
            Full Name
          </label>
          <input onChange={(e) => setName(e.target.value)} className="appearance-none block w-full  border text-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white " type="text" placeholder="Joko" value={name_} />
          {errors.tname && <p className='text-red-600'>{errors.tname}</p>}
        </div>

        {/* Password */}

        <div className="flex flex-col">
          <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
            Password
          </label>
          <Link to={`/user/lockedpassword/${name}`} className='text-blue-400 hover:opacity-70'>Relock User Password</Link>
        </div>

        {/* NRP / NISN / KTP */}

        <div className="flex flex-col">
          <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
            NRP / NISN / KTP
          </label>
          <input onChange={(e) => setNrpNisnKtp(e.target.value)} className="appearance-none block w-full  border text-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white " type="text" placeholder="Joko" value={nrpNisnKtp} />
          {errors.tnrpNisnKtp && <p className='text-red-600'>{errors.tnrpNisnKtp}</p>}
        </div>

        {/* Course */}

        <div className="flex flex-col">
          <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
            Course
          </label>
          <div className="grid grid-cols-2 gap-2">
            {allCourse && allCourse.map((course, index) => {
              const courseName = course.name.replaceAll('_', ' ');
              return <div key={index} className="flex flex-row-reverse justify-end gap-2 items-center">
                <label htmlFor={course.name} className="capitalize">{courseName}</label>
                <input onClick={() => handleCourses(course.name)} type="checkbox" name={course.name} id={course.name} defaultChecked={arrayNameAllCourse.includes(course.name)} />
              </div>
            })}
          </div>
        </div>

        {/* Role */}

        <div className="flex flex-col">
          <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
            Role
          </label>
          <div className="relative">
            <select className="hover:cursor-pointer appearance-none w-full   border text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-black" id="grid-state" onChange={(event) => handleRole(event.target.value)}
              value={role}>
              <option value={'user'}>User</option>
              <option value={'mentor'}>Mentor</option>
              <option value={'admin'}>Admin</option>
            </select>
          </div>
        </div>

        {/* MentorOf */}

        {(role === 'mentor' || role === 'admin') &&
          <div className="flex flex-col">
            <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
              Mentor Of
            </label>
            <div className="relative">
              <select className="hover:cursor-pointer appearance-none w-full   border text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-black capitalize" id="grid-state" onChange={(event) => setCourseId(event.target.value)} value={courseId}>
                {allCourse && allCourse.map((course, index) => {
                  const courseName = course.name.replaceAll('_', ' ');
                  return <option value={course.id} key={index} className="capitalize">{courseName}</option>
                })}
              </select>
            </div>
          </div>}

        {/* Class */}

        <div className="flex flex-col">
          <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
            Instance
          </label>
          <input onChange={(e) => setInstance(e.target.value)} className="appearance-none block w-full  text-black  border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white" type="text" placeholder="12"
            value={instance} />
        </div>

        {/* Major */}

        <div className="flex flex-col">
          <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
            Class/Major
          </label>
          <input onChange={(e) => setClassMajor(e.target.value)} className="appearance-none block w-full  text-black  border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white" type="text" placeholder="Rekayasa Perangkat Lunak" value={classMajor} />
        </div>

        {/* Description */}

        <div className="flex flex-col">
          <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
            Description (Optional)
          </label>
          <textarea onChange={(e) => setDescription(e.target.value)} className="appearance-none block w-full  text-black  border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white" defaultValue={description}></textarea>
        </div>

        {/* Submit */}

        <div className="">
          <button onClick={(e) => UpdateHandler(e)} className="bg-blue-700 rounded-md p-3 px-6 text-white w-full text-xl font-semibold hover:bg-gray-900 duration-300">Update</button>
        </div>



      </section>
    </form>
  );
}

export default Update;