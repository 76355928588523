import axios from "axios"
import { jwtDecode } from "jwt-decode"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

import Loading from '../../loading.jsx'

// Import Metadata
import Metadata from '../../../common/metadata.js'


const initialErrors = {
    tname: '',
    tnrpNisnKtp: '',
    tpassword: '',
};

const Create = () => {
    document.title = `Create User ${Metadata.exTitle}`

    const [allMentor, setAllMentor] = useState(null)
    const [allCourse, setAllCourse] = useState(null)
    const [mentorId, setMentorId] = useState(null)
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [nrpNisnKtp, setNrpNisnKtp] = useState('')
    const [instance, setInstance] = useState('')
    const [classMajor, setClassMajor] = useState('')
    const [role, setRole] = useState('user')
    const [courses, setCourses] = useState('')
    const [mentorCourseId, setMentorCourseId] = useState(null)
    const [description, setDescription] = useState('')
    const [errors, setErrors] = useState(initialErrors)
    const [accessToken, setAccessToken] = useState('')
    const [expire, setExpire] = useState('')
    const history = useHistory()

    useEffect(() => {
        const fetchDataMentor = async (accessToken_) => {
            try {
                const response = await axios.get(`${Metadata.apiOrigin}/user/getAllMentor`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken_}`
                    }
                });
                setAllMentor(response.data.rows);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchDataCourse = async (accessToken_) => {
            try {
                const response = await axios.get(`${Metadata.apiOrigin}/getAllCourse`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken_}`
                    }
                });
                setAllCourse(response.data.rows);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const refreshTokenAndFetchData = async () => {
            const accessToken_ = await refreshToken();
            fetchDataMentor(accessToken_)
            fetchDataCourse(accessToken_)
        };

        refreshTokenAndFetchData();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${Metadata.apiOrigin}/token`)
            setAccessToken(response.data.accessToken)
            const decoded = jwtDecode(response.data.accessToken)
            setExpire(decoded.exp)
            return response.data.accessToken
        } catch (error) {
        }
    }

    // refreshToken()
    // refreshToken()
    const axiosJWT = axios.create()

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date()
        if (expire * 1000 < currentDate.getTime()) {
            try {
                const response = await axios.get(`${Metadata.apiOrigin}/token`);
                setAccessToken(response.data.accessToken);
                config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                const decoded = jwtDecode(response.data.accessToken);
                setExpire(decoded.exp);
            } catch (error) {
                // Handle token refresh failure (e.g., logout the user)
                // You can catch the error here and handle it appropriately
                // (e.g., show a notification, redirect to login page, etc.)
                return Promise.reject(error);
            }
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    })

    function handleCourses(value) {
        let coursesArray = courses.split('&')
        if (coursesArray.length != 0) {
            if (coursesArray.includes(value)) {
                let i = coursesArray.indexOf(value)
                coursesArray.splice(i, 1)
            } else {
                coursesArray.push(value)
            }
        } else {
            coursesArray.push(value)
        }

        let courses_ = ''
        coursesArray.forEach(courseArray => {
            if (courses_ !== '')
                courses_ += '&' + courseArray
            else
                courses_ += courseArray
        });
        setCourses(courses_)
    }

    function handleRole(value) {
        if (value !== 'mentor' && value != 'admin') {
            setMentorCourseId(null)
        } else {
            if (mentorCourseId === null) {
                setMentorCourseId(allCourse[0].id)
            }
        }

        setRole(value)
    }

    const postDataHandler = (e) => {
        e.preventDefault()
        PostData();
    };

    function PostData() {
        const name__ = (name === '') ? 'Name required *' : ''
        const password_ = (password === '') ? 'Password required *' : ''
        const nrpNisnKtp_ = (nrpNisnKtp === '') ? 'NRP / NISN / KTP required *' : ''

        if (role === '') {
            setRole(null)
        }
        if (instance === '') {
            setInstance(null)
        }
        if (classMajor === '') {
            setClassMajor(null)
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            tname: name__,
            tpassword: password_,
            tnrpNisnKtp: nrpNisnKtp_,
        }))

        if (name__ === '' && password_ === '' && nrpNisnKtp_ === '') {
            axiosJWT.post(`${Metadata.apiOrigin}/user/create`, {
                "name": name,
                "nrp_nisn_ktp": nrpNisnKtp,
                "mentor_id": mentorId,
                "password": password,
                "role": role,
                "course_id": (mentorCourseId !== '' || mentorCourseId !== null || role === 'mentor') ? mentorCourseId : null,
                "instance": instance,
                "classmajor": classMajor,
                "description": description,
                "courses": courses
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(function (response) {
                history.push('/data/users')
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    if (!allMentor && !allCourse) {
        return <Loading />
    }

    return (
        <form className="bg-white py-14  w-full h-full flex justify-center items-center">
            <section className="bg-transparent border backdrop-blur-2xl bg-opacity-30 shadow-md  w-[90%]  max-w-md p-10 bg-white rounded-xl flex flex-col gap-2">
                <div>
                    <h1 className="text-3xl font-semibold pb-10">
                        Create a New User
                    </h1>
                </div>

                {/* Mentor */}

                <div className="flex flex-col">
                    <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                        Mentor
                    </label>
                    <div className="relative">
                        <select className="hover:cursor-pointer appearance-none w-full   border text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-black" id="grid-state" onChange={(event) => setMentorId(event.target.value)}>
                            <option value={''}>Without Mentor</option>
                            {allMentor.map((mentor, index) => (
                                <option value={mentor.id} key={index}>{mentor.name}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Name */}

                <div className="flex flex-col">
                    <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                        Full Name
                    </label>
                    <input onChange={(e) => setName(e.target.value)} className="appearance-none block w-full  border text-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white " type="text" placeholder="Joko" />
                    {errors.tname && <p className='text-red-600'>{errors.tname}</p>}
                </div>

                {/* Password */}

                <div className="flex flex-col">
                    <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                        Password
                    </label>
                    <input onChange={(e) => setPassword(e.target.value)} className="appearance-none block w-full  text-black  border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white" type="password" placeholder="******************" />
                    <p className="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p>
                    {errors.tpassword && <p className='text-red-600'>{errors.tpassword}</p>}
                </div>

                {/* NRP / NISN / KTP */}

                <div className="flex flex-col">
                    <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                        NRP / NISN / KTP
                    </label>
                    <input onChange={(e) => setNrpNisnKtp(e.target.value)} className="appearance-none block w-full  border text-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white " type="text" placeholder="Joko" />
                    {errors.tnrpNisnKtp && <p className='text-red-600'>{errors.tnrpNisnKtp}</p>}
                </div>

                {/* Course */}

                <div className="flex flex-col">
                    <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                        Course
                    </label>
                    <div className="grid grid-cols-2 gap-2">
                        {allCourse && allCourse.map((course, index) => {
                            const courseName = course.name.replaceAll('_', ' ');
                            return <div key={index} className="flex flex-row-reverse justify-end gap-2 items-center">
                                <label htmlFor={course.name} className="capitalize">{courseName}</label>
                                <input onClick={() => handleCourses(course.name)} type="checkbox" name={course.name} id={course.name} />
                            </div>
                        })}
                    </div>
                </div>

                {/* Role */}

                <div className="flex flex-col">
                    <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                        Role
                    </label>
                    <div className="relative">
                        <select className="hover:cursor-pointer appearance-none w-full   border text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-black" id="grid-state" onChange={(event) => handleRole(event.target.value)}>
                            <option value={'user'}>User</option>
                            <option value={'mentor'}>Mentor</option>
                            <option value={'admin'}>Admin</option>
                        </select>
                    </div>
                </div>

                {/* MentorOf */}

                {(role === 'mentor' || role === 'admin') &&
                    <div className="flex flex-col">
                        <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                            Mentor Of
                        </label>
                        <div className="relative">
                            <select className="hover:cursor-pointer appearance-none w-full   border text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-black" id="grid-state" onChange={(event) => setMentorCourseId(event.target.value)}>
                                {allCourse.map((course, index) => {
                                    const courseName = course.name.replaceAll('_', ' ');
                                    return <option value={course.id} key={index} className="capitalize">{courseName}</option>
                                })}
                            </select>
                        </div>
                    </div>
                }

                {/* Class */}

                <div className="flex flex-col">
                    <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                        Instance (Optional)
                    </label>
                    <input onChange={(e) => setInstance(e.target.value)} className="appearance-none block w-full  text-black  border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white" type="text" placeholder="Gema Foundation" />
                </div>

                {/* Major */}

                <div className="flex flex-col">
                    <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                        Class/Major (Optional)
                    </label>
                    <input onChange={(e) => setClassMajor(e.target.value)} className="appearance-none block w-full  text-black  border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white" type="text" placeholder="12 / Software Engineer" />
                </div>

                {/* Description */}

                <div className="flex flex-col">
                    <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                        Description (Optional)
                    </label>
                    <textarea onChange={(e) => setDescription(e.target.value)} className="appearance-none block w-full  text-black  border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white" type="text"></textarea>
                </div>

                {/* Submit */}

                <div className="">
                    <button onClick={(e) => postDataHandler(e)} className="bg-blue-700 rounded-md p-3 px-6 text-white w-full text-xl font-semibold hover:bg-gray-900 duration-300">Create</button>
                </div>

            </section>
        </form >
    );
}

export default Create;