import axios from "axios"
import { jwtDecode } from "jwt-decode"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

import Loading from '../../loading.jsx'

// Import Metadata
import Metadata from '../../../common/metadata.js'


const initialErrors = {
    toldpassword: '',
    tnewpassword: '',
    tnewpasswordconfirm: '',
};

const ResetPassword = () => {
    document.title = `Reset Password ${Metadata.exTitle}`

    const [userId, setUserId] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
    const [error, setError] = useState('')
    const [errors, setErrors] = useState(initialErrors)
    const [accessToken, setAccessToken] = useState('')
    const [expire, setExpire] = useState('')
    const history = useHistory()

    useEffect(() => {
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${Metadata.apiOrigin}/token`)
            setAccessToken(response.data.accessToken)
            const decoded = jwtDecode(response.data.accessToken)
            setUserId(decoded.userId)
            setExpire(decoded.exp)
            return response.data.accessToken
        } catch (error) {
        }
    }

    // refreshToken()
    const axiosJWT = axios.create()

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date()
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${Metadata.apiOrigin}/token`)
            setAccessToken(response.data.accessToken)
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            const decoded = jwtDecode(response.data.accessToken)
            setUserId(decoded.userId)
            setExpire(decoded.exp)
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    })

    const postDataHandler = (e) => {
        e.preventDefault()
        PostData();
    };

    function PostData() {
        const oldPassword_ = (oldPassword === '') ? 'Old Password required *' : ''
        const newPassword_ = (newPassword === '') ? 'New Password required *' : ''
        const newPasswordConfirm_ = (newPasswordConfirm === '') ? 'New Password Confirm required *' : ''

        setErrors((prevErrors) => ({
            ...prevErrors,
            toldpassword: oldPassword_,
            tnewpassword: newPassword_,
            tnewpasswordconfirm: newPasswordConfirm_
        }))

        if (newPassword !== newPasswordConfirm) {
            setError('New Password and New Password Confirm not match')
        }

        if (oldPassword_ === '' && newPassword_ === '' && newPasswordConfirm_ === '') {
            axiosJWT.post(`${Metadata.apiOrigin}/user/update/password`, {
                "id": userId,
                "oldPassword": oldPassword,
                "newPassword": newPassword
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then(function (response) {
                history.push('/profile')
            }).catch(function (err) {
                if (err.response) {
                    setError('Old Password Incorrect')
                }
            });
        }
    }

    if (!userId) {
        return <Loading />
    }

    return (

        <form className="bg-white py-14  w-full h-screen flex justify-center items-center">
            <section className=" bg-transparent border backdrop-blur-2xl bg-opacity-30 shadow-md  w-[90%]  max-w-md p-10 bg-white rounded-xl flex flex-col gap-2">
                <div className="flex flex-col gap-2">
                    <h1 className="text-3xl font-semibold">
                        Reset Password
                    </h1>
                    {error && <p className="text-red-400">{error}</p>}
                </div>

                {/* Old Password */}
                <div className="flex flex-col">
                    <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                        Old Password
                    </label>
                    <input onChange={(e) => setOldPassword(e.target.value)} className="appearance-none block w-full  border text-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white " type="password" />
                    {errors.toldpassword && <p className='text-red-600'>{errors.toldpassword}</p>}
                </div>

                {/* New Password */}
                <div className="flex flex-col">
                    <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                        New Password
                    </label>
                    <input onChange={(e) => setNewPassword(e.target.value)} className="appearance-none block w-full  border text-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white " type="password" />
                    {errors.tnewpassword && <p className='text-red-600'>{errors.tnewpassword}</p>}
                </div>

                {/* New Password Confirm */}
                <div className="flex flex-col">
                    <label className="block uppercase tracking-wide text-black text-xs font-bold mb-2" >
                        Confirm New Password
                    </label>
                    <input onChange={(e) => setNewPasswordConfirm(e.target.value)} className="appearance-none block w-full  border text-black  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-black focus:bg-white " type="password" />
                    {errors.tnewpasswordconfirm && <p className='text-red-600'>{errors.tnewpasswordconfirm}</p>}
                </div>

                {/* Submit */}
                <div className="">
                    <button onClick={(e) => postDataHandler(e)} className="bg-blue-700 rounded-md p-3 px-6 text-white w-full text-xl font-semibold hover:bg-gray-900 duration-300">Update Password</button>
                </div>

            </section>
        </form>
    );
}

export default ResetPassword;