import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';
import { FaBars, FaXmark } from "react-icons/fa6";
import { jwtDecode } from 'jwt-decode';

// Import Metadata
import Metadata from '../../common/metadata.js'
import { TbArrowBarToRight } from 'react-icons/tb';

const Navbar = () => {
    const [name, setName] = useState('')
    const [courses, setCourses] = useState('')
    const [role, setRole] = useState('')
    const [expire, setExpire] = useState('')
    const history = useHistory()

    useEffect(() => {
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${Metadata.apiOrigin}/token`)
            const decoded = jwtDecode(response.data.accessToken)
            setName(decoded.name)
            setRole(decoded.role)
            setExpire(decoded.exp)

            let courses = (decoded.courses).split('&')
            setCourses(courses)
        } catch (error) {
        }
    }

    // refreshToken()
    const axiosJWT = axios.create()

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date()
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${Metadata.apiOrigin}/token`)
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            const decoded = jwtDecode(response.data.accessToken)
            setName(decoded.name)
            setRole(decoded.role)
            setExpire(decoded.exp)

            let courses = (decoded.courses).split('&')
            setCourses(courses)
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    })

    const Logout = async () => {
        try {
            await axiosJWT.delete(`${Metadata.apiOrigin}/logout`)
            history.push('/')
        } catch (error) {
            // console.log(error);
        }
    }

    const handleLogout = () => {
        Logout()
        setOpen(!open)
    }

    const [open, setOpen] = useState(true);
    const now = new Date()

    if (!name) {
        return <></>
    }

    return (
        <header className="w-full top-0 left-0 md:hidden visible" id="top">
            <nav className="shadow-md static z-50 h-14 bg-transparent flex flex-col bg-blend-multiply">

                {/* Title */}

                <div className='flex flex-row py-[5px] w-full justify-between items-center h-full'>
                    <div className="flex items-center">
                        <Link to="/" className="text-xl text-gray-900 selection:text-black font-semibold italic ml-4">PRESENCE APP</Link>
                    </div>


                    <div className="flex flex-col gap-10 justify-center items-center mr-4">
                        <button onClick={() => setOpen(!open)} className={`shadow-xl text-black text-xl `}>
                            <FaBars name={open ? 'close' : 'menu'} className={` ${open ? 'visible' : 'hidden'}`} />
                            <FaXmark name={open ? 'close' : 'menu'} className={` ${open ? 'hidden' : 'visible'}`}></FaXmark>
                        </button>
                    </div>
                </div>
            </nav>
            <section className={`absolute w-full h-screen bg-white z-[6] ${open ? 'hidden' : 'visible'}`}>
                <div className="flex flex-col items-center gap-1">
                    <div className={`block rounded-full shadow-md bg-[url('./img/deafultUser.webp')] bg-cover jawa bg-center w-40 h-40`}>
                    </div>
                    <div className="flex flex-col items-center">
                        <h3 className={`font-bold text-xl `}>{name}</h3>
                        <p className={`italic `}>{role}</p>
                        <p className={`italic hidden `}>User</p>
                    </div>
                </div>
                <div className="flex flex-col h-full px-7">
                    <ul className={`font-medium flex flex-col space-y-7 mt-10 text-xl`}>
                        <li>
                            <Link to="/profile" onClick={() => setOpen(!open)} className={`flex items-center text-gray-900 rounded-lg hover:bg-gray-200 hover:text-blue-500 hover:border-black p-2`}>
                                <i className="fa-solid fa-chart-pie" name={open ? 'close' : 'menu'}></i>
                                <span className={`flex-1 ms-3 whitespace-nowrap `}>Profile</span>
                            </Link>
                        </li>

                        {(role === 'admin' || role === 'mentor') &&
                            <li>
                                <Link to="/data/users" onClick={() => setOpen(!open)} className={`flex items-center text-gray-900 rounded-lg  hover:text-blue-500 hover:bg-gray-200 p-2 `}>
                                    <i className="fa-solid fa-address-book" name={open ? 'close' : 'menu'}></i>
                                    <span className={`flex-1 ms-3 whitespace-nowrap `}>User Data</span>
                                </Link>
                            </li>
                        }

                        {courses != 0 &&
                            <li className="flex flex-col gap-2 p-2">
                                <div className="flex justify-between gap-2 items-center">
                                    <div className="flex gap-2 items-center">
                                        <i className="fa-solid fa-person-chalkboard " name={open ? 'close' : 'menu'}></i>
                                        <h2>My Presence</h2>
                                    </div>
                                    <TbArrowBarToRight className="w-5 h-5" />
                                </div>
                                <div className="flex flex-col pl-4">
                                    {courses.map((course, index) => {
                                        const label = course.replaceAll('_', ' ');
                                        return <a href={`/mypresence/${course}`} onClick={() => setOpen(!open)} className={`flex w-full items-center text-gray-900 rounded-lg  hover:text-blue-500 hover:bg-gray-200 p-2 `} key={index}>
                                            <span className={`flex-1 ms-3 whitespace-nowrap visible capitalize`}>{label}</span>
                                        </a>
                                    })}
                                </div>
                            </li>}

                        <li>
                            <button onClick={() => handleLogout()} className={`flex items-center text-gray-900 rounded-lg  hover:text-blue-500 hover:bg-gray-200 p-2 `}>
                                <i className="fa-solid fa-right-to-bracket" name={open ? 'close' : 'menu'}></i>
                                <span className={`flex-1 ms-3 whitespace-nowrap`}>Sign Out</span>
                            </button>
                        </li>
                    </ul>
                    <div className="flex flex-col gap-10 justify-center items-center">

                        <h1 className={`text-md font-semibold text-center `}>{JSON.stringify(now.toDateString()).replace('"', '').replace('"', '')}</h1>
                    </div>
                </div>
            </section>
        </header>
    );
}

export default Navbar;