import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode"
import axios from 'axios'
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";

import Loading from '../../loading.jsx'

// Import Metadata
import Metadata from '../../../common/metadata.js'

const Profile = () => {
    document.title = `Profile ${Metadata.exTitle}`

    const [name, setName] = useState('')
    const [nrpNisnKtp, setNrpNisnKtp] = useState('')
    const [instance, setInstance] = useState('')
    const [classMajor, setClassMajor] = useState('')
    const [description, setDescription] = useState('')
    const [accessToken, setAccessToken] = useState('')
    const [expire, setExpire] = useState('')
    const history = useHistory()

    useEffect(() => {
        refreshToken();
    }, []); // Kosongkan dependensi untuk memastikan efek hanya berjalan sekali

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${Metadata.apiOrigin}/token`)
            setAccessToken(response.data.accessToken)
            const decoded = jwtDecode(response.data.accessToken)
            setName(decoded.name)
            setNrpNisnKtp(decoded.nrpNisnKtp)
            setInstance(decoded.instance)
            setClassMajor(decoded.classmajor)
            setDescription(decoded.description)
            setExpire(decoded.exp)
            return { userId: decoded.userId, accessToken: response.data.accessToken }
        } catch (error) {
            if (error.response) {
                history.push('/')
            }
        }
    }

    // refreshToken()
    const axiosJWT = axios.create()

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date()
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${Metadata.apiOrigin}/token`)
            setAccessToken(response.data.accessToken)
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            const decoded = jwtDecode(response.data.accessToken)
            setName(decoded.name)
            setNrpNisnKtp(decoded.nrpNisnKtp)
            setInstance(decoded.instance)
            setClassMajor(decoded.classmajor)
            setDescription(decoded.description)
            setExpire(decoded.exp)
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    })

    if (!name) {
        return <Loading />
    }

    return (
        <div className="bg-white w-full h-screen flex  items-center">
            <div className=" max-w-4xl flex items-center justify-center h-auto lg:h-screen flex-row mx-auto my-32 lg:my-0">

                {/* <!--Main Col--> */}
                <div id="profile"
                    className="w-full z-[2] lg:w-3/5 rounded-lg md:rounded-r-none  shadow-2xl border backdrop-blur-xl bg-opacity-30 bg-white mx-0">


                    <div className="p-4 md:p-1 text-center lg:text-left  flex flex-col gap-2">
                        {/* <!-- Image for mobile view--> */}
                        <div className="block rounded-full shadow-xl mx-auto backdrop-blur-none -mt-16 w-48 h-48 bg-opacity-100 bg-cover bg-[url('./img/deafultUser.webp')] bg-center"
                        ></div>

                        <div className="p-10">
                            <h1 className="text-3xl font-bold pt-8 lg:pt-0 text-center">Hello, {name}</h1>
                            <p className="text-center w-full">{nrpNisnKtp}</p>
                            <div className="mx-auto lg:mx-0  w-4/5 pt-3 border-b-2 border-blue-500 opacity-25"></div>
                            <div className="flex flex-col gap-5">
                                <div className="flex flex-row items-center justify-center gap-2">
                                    <div className="text-base font-bold flex gap-1 items-center">
                                        <i className="fa-solid fa-school text-blue-600"></i>
                                        <p>{instance ? instance : 'empty'}</p>
                                    </div>
                                    <div className="text-base font-bold flex gap-1 items-center">
                                        <i className="fa-solid fa-graduation-cap text-blue-600 "></i>
                                        <p>{classMajor ? classMajor : 'empty'}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <Link to={`/reset-password`} className={`text-blue-400 hover:opacity-70 text-center`}>Reset My Password</Link>
                                    <p className="text-center">{description}</p>
                                </div>
                                {/* {!data?.nowIsPresent && <>
                                    <div className="flex items-center justify-center">
                                        <p className="text-sm w-40 text-center">To fill the attendence click the button below.</p>
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <button onClick={() => presentHandler()} className="bg-blue-700 hover:bg-blue-900 selection:text-white text-white font-bold py-2 px-4 rounded-md w-full">
                                            Present
                                        </button>
                                    </div>
                                </>
                                } */}
                            </div>
                        </div>
                    </div>

                </div>

                {/* <!--Img Col--> */}
                <div className="w-fit absolute flex justify-center z-[1] md:static ">

                    {/* <!-- Big profile image Bear Alien--> */}
                    <div className="w-[358px] h-[538px] bg-blue-700 rounded-lg  relative overflow-hidden lg:rounded-lg shadow-2xl bg-opacity-30  lg:block ">
                        <div className="bg-blue-400 w-24 h-24 rounded-full shadow-xl absolute  mt-[-3%] ml-[-4%] z-[1]"></div>
                        <div className="bg-blue-400 w-48 h-48 rounded-full shadow-xl absolute  mt-[-3%] ml-[-4%] z-[1]"></div>
                        <div className="bg-blue-400 w-24 h-24 rounded-full  absolute  mt-[36%] ml-[40%] z-[1]"></div>
                        <div className="bg-red-400 w-20 h-20 rounded-full  absolute  mt-[38%] ml-[42%] z-[2]"></div>
                        <div className="bg-blue-400 w-12 h-12 rounded-full  absolute  mt-[34%] ml-[36%] z-[1]"></div>
                        <div className="bg-black w-7 h-7 rounded-full  absolute  mt-[18%] ml-[29%] z-[3]"></div>
                        <div className="bg-slate-50 w-12 h-12 rounded-full  absolute  mt-[12%] ml-[30%] z-[2]"></div>
                        <div className="bg-black w-7 h-7 rounded-full  absolute  mt-[35%] ml-[15%] z-[3]"></div>
                        <div className="bg-slate-50 w-12 h-12 rounded-full  absolute  mt-[30%] ml-[16%] z-[2]"></div>
                        <div className="bg-amber-800 w-12 h-12 rounded-full  absolute  mt-[87%] ml-[68%] z-[2]"></div>
                        <div className="bg-amber-800 w-12 h-12 rounded-full  absolute  mt-[125%] ml-[36%] z-[2]"></div>
                        <div className="bg-black w-12 h-12 rounded-full  absolute  mt-[122%] ml-[66%] z-[3]"></div>
                        <div className="bg-amber-700 w-24 h-24 rounded-full  absolute  mt-[120%] ml-[66%] z-[2]"></div>
                        <div className="bg-black w-7 h-7 rounded-full  absolute  mt-[122%] ml-[50%] z-[3]"></div>
                        <div className="bg-slate-50 w-14 h-14 rounded-full  absolute  mt-[120%] ml-[50%] z-[2]"></div>
                        <div className="bg-black w-7 h-7 rounded-full  absolute  mt-[110%] ml-[72%] z-[3]"></div>
                        <div className="bg-slate-50 w-14 h-14 rounded-full  absolute  mt-[108%] ml-[72%] z-[2]"></div>
                        <div className="bg-amber-600 w-28 h-28 rounded-full shadow-xl absolute  mt-[115%] ml-[30%] z-[1]"></div>
                        <div className="bg-amber-600 w-28 h-28 rounded-full shadow-xl absolute  mt-[80%] ml-[60%] z-[1]"></div>
                        <div className="bg-amber-800 w-64 h-64 rounded-full shadow-xl absolute  mt-[95%] ml-[44%] z-[1]"></div>
                    </div>
                    {/* <!-- Image from: http://unsplash.com/photos/MP0IUfwrn0A --> */}



                </div>

            </div>
        </div>
    );
}

export default Profile;