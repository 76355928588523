import React from 'react'
import { TbLoader } from 'react-icons/tb'

const Loading = () => {
  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <div className='flex flex-col gap-2'>
        <span className='flex gap-2 items-center'>
          <TbLoader className='w-7 h-7 animate-spin' />
          Loading
        </span>
        <span>wait a minute</span>
      </div>
    </div>
  )
}

export default Loading