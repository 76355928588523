import axios from "axios";
import { useState } from "react"
import { useHistory } from "react-router-dom";

// Import Metadata
import Metadata from '../../../common/metadata.js'
import { TbLock, TbUser } from "react-icons/tb";

const initialErrors = {
    tnrpNisnKtp: '',
    tpassword: '',
};

const Login = () => {
    document.title = `Login ${Metadata.exTitle}`

    const [nrpNisnKtp, setNrpNisnKtp] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [errors, setErrors] = useState(initialErrors)

    const history = useHistory()

    const loginHandler = () => {
        Login();
    };

    function Login() {
        setError('')

        const nrpNisnKtp_ = (nrpNisnKtp === '') ? 'Name required *' : ''
        const password_ = (password === '') ? 'Password required *' : ''

        setErrors((prevErrors) => ({
            ...prevErrors,
            tnrpNisnKtp: nrpNisnKtp_,
            tpassword: password_,
        }))

        if (nrpNisnKtp_ === '' && password_ === '') {
            axios.post(`${Metadata.apiOrigin}/login`, {
                "nrp_nisn_ktp": nrpNisnKtp,
                "password": password
            }, 1000).then(function (response) {
                history.push('/profile')

            }).catch(function (err) {
                console.log(err);
                setError('Password or username invalid')
            });
        }
    }

    return (
        <div className=" bg-white w-full h-screen flex justify-center items-center">
            <div className="absolute w-96 h-96 rounded-full blur-xl bg-blue-400"></div>
            <div className="absolute w-96 h-96 rounded-full blur-xl bg-blue-400 top-0 -left-40"></div>
            <div className="absolute w-96 h-96 rounded-full blur-xl bg-blue-400 bottom-0 -right-40"></div>
            <div>
                <form className="bg-transparent backdrop-blur-2xl bg-opacity-30 border bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="pb-4">
                        <h1 htmlFor="" className="text-2xl font-semibold ">Login to Your Account</h1>
                        {error && <p className="text-red-400">{error}</p>}
                    </div>
                    <div className="mb-4">
                        <label className="text-gray-700 text-sm font-bold mb-2 flex items-center gap-1" >
                            <TbUser className="w-4 h-4" />
                            NRP / NISN / KTP
                        </label>
                        <input className="shadow appearance-none border rounded w-[300px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="number" placeholder="NRP / NISN / KTP" onChange={(event) => setNrpNisnKtp(event.target.value)} />
                        {errors.tnrpNisnKtp && <p className='text-red-600'>{errors.tnrpNisnKtp}</p>}
                    </div>
                    <div className="mb-6">
                        <label className="text-gray-700 text-sm font-bold mb-2 flex items-center gap-1" >
                            <TbLock className="w-4 h-4" />
                            Password
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************" onChange={(event) => setPassword(event.target.value)} />
                        {errors.tpassword && <p className='text-red-600'>{errors.tpassword}</p>}
                    </div>
                    <div className="flex items-center justify-between">
                        <button onClick={() => loginHandler()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                            Sign In
                        </button>
                    </div>
                </form>
                <p className="text-center text-black text-xs">
                    <b>&copy;2024 Gema Foundation.</b> All rights reserved.
                </p>
            </div>
        </div>
    );
}

export default Login;