
import Navbar from './components/navigations/Navbar';
import Login from './components/pages/auth/Login';
import Create from './components/pages/mentor/CreateUser';
import DataUser from './components/pages/mentor/DataUser';
import Update from './components/pages/mentor/UpdateUser';
import DataPresenceUser from './components/pages/mentor/DataPresenceUser';
import LockedPassword from './components/pages/mentor/UpdateUserPassword';
import DataStudent from './components/pages/mentor/DataStudent';
import Profile from './components/pages/user/Profile';
import ResetPassword from './components/pages/user/ResetPassword';
import DataPresence from './components/pages/user/DataPresence';
import Sidebar from './components/navigations/Sidebar';
import MaybeShowNavbar from './components/showsidebar/MaybeShowNavbar';
import MaybeShowSidebar from './components/showsidebar/MaybeShowSidebar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import UserProfile from './components/pages/mentor/UserProfile';


function App() {

  return (
    <Router >
      <div>
        <MaybeShowNavbar>
          <Navbar />
        </MaybeShowNavbar>
        <div className="relative flex flex-row">
          <Route>
            <MaybeShowSidebar>
              <Sidebar />
            </MaybeShowSidebar>
          </Route>

          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route exact path="/data/users">
              <DataUser />
            </Route>
            <Route exact path="/user/create">
              <Create />
            </Route>
            <Route exact path="/user/update/:name">
              <Update />
            </Route>
            <Route exact path="/user/lockedpassword/:name">
              <LockedPassword />
            </Route>
            <Route exact path="/user/presence/:course/:name">
              <DataPresenceUser />
            </Route>
            <Route exact path="/user/profile/:name">
              <UserProfile />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/reset-password">
              <ResetPassword />
            </Route>
            <Route exact path="/mypresence/:course">
              <DataPresence />
            </Route>
            <Route exact path="/mystudent">
              <DataStudent />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
