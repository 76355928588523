import { Link } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// Import Metadata
import Metadata from '../../common/metadata.js'
import { TbArrowBarToRight, TbArrowRight, TbMenu2 } from "react-icons/tb";

const Sidebar = () => {

    const history = useHistory()
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('')
    const [courses, setCourses] = useState('')
    const [role, setRole] = useState('')
    const [expire, setExpire] = useState('')

    useEffect(() => {
        refreshToken()
    }, [])

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${Metadata.apiOrigin}/token`)
            const decoded = jwtDecode(response.data.accessToken)
            setName(decoded.name)
            setCourses(decoded.courses)
            setRole(decoded.role)
            setExpire(decoded.exp)

            if (decoded.courses != '' || decoded.courses != null) {
                let courses = (decoded.courses).split('&')
                setCourses(courses)
            }
        } catch (error) {
        }
    }

    // refreshToken()
    const axiosJWT = axios.create()

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date()
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${Metadata.apiOrigin}/token`)
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            const decoded = jwtDecode(response.data.accessToken)
            setName(decoded.name)
            setRole(decoded.role)
            setExpire(decoded.exp)

            let courses = (decoded.courses).split('&')
            setCourses(courses)
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    })

    const Logout = async () => {
        try {
            await axiosJWT.delete(`${Metadata.apiOrigin}/logout`)
            history.push('/')
        } catch (error) {
            // console.log(error);
        }
    }

    const handleLogout = () => {
        Logout()
        setOpen(!open)
    }

    const now = new Date()

    if (!name) {
        return <></>
    }

    return (
        <nav className="">
            <aside id="" className={`fixed top-0 left-0 z-40 h-screen transition-all ease-in duration-300 translate-x-0  bg-white w-64`} aria-label="Sidebar">
                <div className="h-full flex flex-col gap-5 px-3 py-4 overflow-y-auto bg-transparent shadow-xl overflow-hidden border">
                    <div className="flex items-center justify-center">
                        <Link to="/" className={`text-3xl text-gray-900 selection:text-black font-semibold italic  visible`}>PRESENCE APP</Link>
                    </div>
                    <div className="flex flex-col items-center gap-1">
                        <div className={`block rounded-full shadow-md bg-[url('./img/deafultUser.webp')] bg-cover jawa bg-center w-40 h-40`}>
                        </div>
                        <div className="flex flex-col items-center">
                            <h3 className={`font-bold text-xl visible`}>{name}</h3>
                            <p className={`italic visible`}>{role}</p>
                            <p className={`italic hidden visible`}>{role}</p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between h-full">
                        <ul className={`font-medium flex flex-col items-start space-y-4 mt-0 text-md`}>
                            <li className={`flex w-full`}>
                                <Link to="/profile" className={`w-full flex items-center text-left text-gray-900 rounded-md duration-200 hover:bg-gray-200 hover:text-blue-500 hover:border-black p-2`}>
                                    <i className="fa-solid fa-chart-pie"></i>
                                    <span className={`flex-1 ms-3 whitespace-nowrap visible`}>Profile</span>
                                </Link>
                            </li>
                            {(role === 'admin' || role === 'mentor') &&
                                <li className={`flex w-full`}>
                                    <Link to="/data/users" className={`flex w-full items-centertext-left text-gray-900 rounded-md  duration-200 hover:text-blue-500 hover:bg-gray-200 p-2`}>
                                        <i className="fa-solid fa-address-book"></i>
                                        <span className={`flex-1 ms-3 whitespace-nowrap visible`}>User Data</span>
                                    </Link>
                                </li>
                            }
                            {(role === 'admin' || role === 'mentor') &&
                                <li className={`flex w-full`}>
                                    <Link to="/mystudent" className={`flex w-full items-centertext-left text-gray-900 rounded-md  duration-200 hover:text-blue-500 hover:bg-gray-200 p-2`}>
                                        <i className="fa-solid fa-address-book"></i>
                                        <span className={`flex-1 ms-3 whitespace-nowrap visible`}>My Students</span>
                                    </Link>
                                </li>
                            }

                            {courses != 0 &&
                                <li className="flex flex-col gap-2 p-2">
                                    <div className="flex justify-between gap-2 items-center">
                                        <div className="flex gap-2 items-center">
                                            <i className="fa-solid fa-person-chalkboard " name={open ? 'close' : 'menu'}></i>
                                            <h2>My Presence</h2>
                                        </div>
                                        <TbArrowBarToRight className="w-5 h-5" />
                                    </div>
                                    <div className="flex flex-col pl-4">
                                        {courses.map((course, index) => {
                                            const label = course.replaceAll('_', ' ');
                                            return <a href={`/mypresence/${course}`} onClick={() => setOpen(!open)} className={`flex w-full items-center text-gray-900 rounded-lg  hover:text-blue-500 hover:bg-gray-200 p-2 `} key={index}>
                                                <span className={`flex-1 ms-3 whitespace-nowrap visible capitalize`}>{label}</span>
                                            </a>
                                        })}
                                    </div>
                                </li>}

                            <li className={`flex w-full`}>
                                <button onClick={() => handleLogout()} className={`w-full flex items-center text-left text-gray-900 rounded-md duration-2 hover:text-blue-500 hover:bg-gray-200 p-2`}>
                                    <i className="fa-solid fa-right-to-bracket" name={'close'}></i>
                                    <span className={`flex-1 ms-3 whitespace-nowrap visible`}>Sign Out</span>
                                </button>
                            </li>
                        </ul>
                        <div className="flex flex-col gap-10 justify-center items-center mb-2">
                            <button onClick={() => setOpen(!open)} className={`bg-blue-700 rounded-full shadow-xl text-white text-2xl p-4`}>
                                <TbMenu2 name={open ? 'close' : 'menu'} className={` text-white ${open ? 'text-2xl visible' : 'text-md hidden'}`} />
                                <TbMenu2 name={open ? 'close' : 'menu'} className={` text-white ${open ? 'text-2xl hidden' : 'text-md visible'}`} />
                            </button>
                            {/* <h1 className={`text-md font-semibold text-center visible`}>Wednesday, 15 February 2024</h1> */}
                            <h1 className={`text-md font-semibold text-center visible`}>{JSON.stringify(now.toDateString()).replace('"', '').replace('"', '')}</h1>
                        </div>
                    </div>
                </div>
            </aside>



        </nav>
    );
}

export default Sidebar;
