import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const MaybeShowSidebar = ({ children }) => {
    const location = useLocation();
    const [showSidebar, setShowSidebar] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768; // Adjust the width based on your design
            setShowSidebar(!isMobile && location.pathname !== "/");
        };

        // Initial check
        handleResize();

        // Event listener for window resize
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [location]);

    return <div>{showSidebar && children}</div>;
};

export default MaybeShowSidebar;
